import React from "react";
import { css } from "@emotion/react";
import { Link } from "gatsby";
import { IconPng } from "@components/Icon";
import { Div } from "@components/Markup";
import { Title } from "@components/Typography";

const ItemLink = ({ text, url, icon }) => {
  return (
    <Link
      to={url}
      css={css`
        display: flex;
        align-items: center;
        max-width: 450px;
        transition: 0.25s all;

        &:hover {
          opacity: 0.5;
        }
      `}
    >
      <Div
        css={css`
          order: ${icon === "arrowLeft" ? 2 : 1};
          margin: 0px 8px;
          text-align: ${icon === "arrowLeft" ? "left" : "right"};
        `}
      >
        <Title tag="h2" size="xs">
          {text}
        </Title>
      </Div>
      <Div
        css={css`
          order: ${icon === "arrowLeft" ? 1 : 2};

          img {
            float: left;
          }
        `}
      >
        <IconPng name={icon} />
      </Div>
    </Link>
  );
};

export default ItemLink;
