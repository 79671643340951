import React from "react";
import SEO from "@components/seo";
import { graphql } from "gatsby";
import Gallery from "@components/Gallery";
import { Section, Container } from "../components/Markup";
import { getDetails } from "../components/utilities";
import ProjectHero from "@components/ProjectHero";
import ProjectDescription from "@components/ProjectDescription";
import Video from "@components/Video";
import PrevNextNav from "@components/PrevNextNav";

const getShortenedName = name => {
  return name.substr(0, name.indexOf(" "));
};

const ProjectPage = ({ data }) => {
  const { nextProject, prevProject, project } = data;
  const {
    name,
    description,
    featuredImage,
    featuredVideoId,
    gallery,
    projectDetails,
  } = project;

  const featuredGallery = gallery;
  const { details, categories, location } = getDetails({ project });
  const seoFeaturedImage = featuredImage ? featuredImage.fluid.src : null;

  return (
    <React.Fragment>
      <SEO
        title={`${name} | Hermann & Valentiny und Partner Architekten | Projekte`}
        description={description}
        image={seoFeaturedImage}
      />
      <ProjectHero
        name={name}
        featuredImage={featuredImage}
        featuredVideoId={featuredVideoId}
        categories={categories}
      />
      <ProjectDescription
        location={location}
        text={description}
        details={details}
        customDetails={projectDetails}
      />
      <Section>
        <Container>
          {featuredVideoId && <Video id={featuredVideoId} />}

          <Gallery items={featuredGallery} />
        </Container>
      </Section>

      <PrevNextNav
        prev={{
          name: getShortenedName(prevProject.name),
          url: `/projekte/${prevProject.slug}`,
          featuredImage: prevProject.featuredImage,
        }}
        next={{
          name: getShortenedName(nextProject.name),
          url: `/projekte/${nextProject.slug}`,
          featuredImage: nextProject.featuredImage,
        }}
      />
    </React.Fragment>
  );
};

export default ProjectPage;

export const query = graphql`
  query ProjectPageQuery(
    $slug: String!
    $nextSlug: String!
    $prevSlug: String!
  ) {
    nextProject: datoCmsProject(slug: { eq: $nextSlug }) {
      slug
      name
      featuredImage {
        fluid {
          src
          srcSet
          aspectRatio
        }
      }
    }
    project: datoCmsProject(slug: { eq: $slug }) {
      name
      description
      featuredImage {
        fluid {
          src
          srcSet
          aspectRatio
        }
      }
      featuredVideoId
      gallery {
        fluid {
          src
          srcSet
          aspectRatio
        }
      }
      buroGewerbe
      wohnen
      lernen
      stadtebau
      kultur
      gesundheit
      projectStatus
      projectPartners
      leistungen
      clientName
      leistungszeitraum
      location {
        latitude
        longitude
      }
      projectDetails {
        text
        title
      }
    }
    prevProject: datoCmsProject(slug: { eq: $prevSlug }) {
      name
      slug
      featuredImage {
        fluid {
          src
          srcSet
          aspectRatio
        }
      }
    }
  }
`;
