import React from "react";
import { css } from "@emotion/react";
import { Div, Container } from "@components/Markup";
import ItemLink from "@components/ItemLink";
import { ThumbnailLink } from "@components/Thumbnail";

const NavItem = ({ featuredImage, url, icon, text }) => {
  return (
    <Div
      flex
      css={css`
        max-width: calc(50% - 16px);
        width: 100%;
        @media (min-width: 768px) {
          max-width: 25%;
          width: 100%;
        }
        flex-direction: column;
        align-items: ${icon === "prev" ? "flex-start" : "flex-end"};
        a.dZNFYZ {
          width: 100%;
        }
      `}
    >
      <ThumbnailLink featuredImage={featuredImage} url={url} />
      <ItemLink
        icon={icon === "prev" ? "arrowLeft" : "arrowRight"}
        text={text}
        url={url}
      />
    </Div>
  );
};

const Nav = ({ prev, next }) => {
  return (
    <Container>
      <Div flex jc="flex-end" pt="md" pb="md" mt="md">
        <Div
          flex
          jc="space-between"
          css={css`
            width: 100%;
          `}
        >
          <NavItem
            featuredImage={prev.featuredImage}
            text={prev.name}
            url={prev.url}
            icon={"prev"}
          />
          <NavItem
            featuredImage={next.featuredImage}
            text={next.name}
            url={next.url}
            icon={"next"}
          />
        </Div>
      </Div>
    </Container>
  );
};

export default Nav;
