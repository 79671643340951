import React from "react";
import { css } from "@emotion/react";
import { Div } from "./Markup";
import GoogleMapReact from "google-map-react";
import MdPin from "react-ionicons/lib/MdPin";

const Marker = () => (
  <div
    css={css`
      height: 3px;
      width: 3px;
      overflow: visible;
      position: relative;
    `}
  >
    <div
      css={css`
        position: absolute;
        bottom: 0;
        left: -15px;
      `}
    >
      <MdPin color={"black"} fontSize={"32px"} />
    </div>
  </div>
);

const defaultProps = {
  zoom: 12,
  center: {
    lat: 48.1290498,
    lng: 16.2759982,
  },
};

const createMapOptions = () => {
  return {
    panControl: false,
    mapTypeControl: false,
    scrollwheel: false,
    clickableIcons: false,
    styles: [
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#e9e9e9",
          },
          {
            lightness: 17,
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f5f5",
          },
          {
            lightness: 20,
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#ffffff",
          },
          {
            lightness: 17,
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#ffffff",
          },
          {
            lightness: 29,
          },
          {
            weight: 0.2,
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [
          {
            color: "#ffffff",
          },
          {
            lightness: 18,
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "geometry",
        stylers: [
          {
            color: "#ffffff",
          },
          {
            lightness: 16,
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f5f5",
          },
          {
            lightness: 21,
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            color: "#dedede",
          },
          {
            lightness: 21,
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            visibility: "on",
          },
          {
            color: "#ffffff",
          },
          {
            lightness: 16,
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            saturation: 36,
          },
          {
            color: "#333333",
          },
          {
            lightness: 40,
          },
        ],
      },
      {
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "geometry",
        stylers: [
          {
            color: "#f2f2f2",
          },
          {
            lightness: 19,
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#fefefe",
          },
          {
            lightness: 20,
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#fefefe",
          },
          {
            lightness: 17,
          },
          {
            weight: 1.2,
          },
        ],
      },
    ],
  };
};

const Map = ({ latitude, longitude }) => {
  return (
    <Div
      css={css`
        /* @media (min-width: 992px) {
          width: 50%;
          padding-right: 16px;
        } */
      `}
    >
      <div
        css={css`
          width: 100%;
          position: relative;
          height: ${0.67 * 527}px;
        `}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyDTTOnp0Z0eVQphBhU-cwDLa6rR2cJAsas",
          }}
          defaultCenter={{ lat: latitude, lng: longitude }}
          defaultZoom={defaultProps.zoom}
          options={createMapOptions}
          yesIWantToUseGoogleMapApiInternals
        >
          <Marker lat={latitude} lng={longitude} />
        </GoogleMapReact>
      </div>
    </Div>
  );
};

export default Map;
