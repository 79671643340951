import React, { useEffect, useRef } from "react";
import { css } from "@emotion/react";
import { Text } from "@components/Typography";
import { Container, Div } from "@components/Markup";
import ProjectMap from "@components/ProjectMap";
import { find } from "lodash";
import gsap from "gsap";

const ProjectDescription = ({ text, details, location, customDetails }) => {
  const textContainerRef = useRef(null);
  const detailsContainerRef = useRef(null);
  let markup = ``;
  const {
    clientName,
    leistungen,
    projectPartners,
    projectStatus,
    leistungszeitraum,
  } = details;

  const statusMap = [
    { en: "completed", de: "Fertiggestellt" },
    { en: "in progress", de: "in Arbeit" },
    { en: "competition project", de: "Wettbewerbsprojekt" },
  ];
  const statusDisplay = find(statusMap, { en: projectStatus });
  // const isIphone = isMobile().apple.phone;

  if (details.projectStatus?.length) {
    markup += `<span class="comment">Status: ${
      statusDisplay ? statusDisplay.de : ""
    }</span><br /><br />`; // QUESTION - how to translate the different statuses? how many>?
  }

  if (details.leistungszeitraum && details.leistungszeitraum.length) {
    markup += `<span class="comment">Leistungszeitraum: </span>${leistungszeitraum}<br /><br />`;
  }

  if (details.clientName?.length) {
    markup += `<span class="comment">Kunde: </span>${clientName}<br /><br />`; // QUESTION - how is client?
  }

  if (details.leistungen?.length) {
    markup += `<span class="comment">Leistungen: </span>${leistungen}<br /><br />`;
  }

  if (details.projectPartners?.length) {
    markup += `<span class="comment">Projektpartner: </span>${projectPartners}<br /><br />`;
  }

  customDetails.forEach((d, i) => {
    markup += `<span class="comment">${d.title}: </span>${d.text}<br /><br />`;
  });

  useEffect(() => {
    // const isIphone = isMobile(window.navigator).apple.phone;

    // if (isIphone) {
    //   textContainerRef.current.innerHTML = `<p class="comment" className="comment">${text}<br /><br /><strong class="comment">${markup}</strong></p>`;
    //   detailsContainerRef.current.innerHTML = ``;
    // } else {
    //   textContainerRef.current.innerHTML = `<p class="comment" className="comment">${text}</p>`;
    //   detailsContainerRef.current.innerHTML = `<p class="comment" className="comment">${markup}</p>`;
    // }

    gsap.set([textContainerRef.current, detailsContainerRef.current], {
      display: "block",
    });
  }, []);

  return (
    <Container>
      <Div
        flex
        css={css`
          flex-direction: column;
          @media (min-width: 768px) {
            margin: 0px -16px;
            flex-direction: row;
          }
        `}
      >
        <Div
          css={css`
            flex-basis: 0;
            flex-grow: 1;
            order: 2;
            margin-top: 32px;

            @media (min-width: 768px) {
              padding: 0px 16px;
              order: 1;
              margin-top: 0px; //test
            }
          `}
        >
          <div
            ref={textContainerRef}
            css={css`
              display: none;
              p {
                white-space: pre-line;
                font-size: 16px;
              }

              @media (min-width: 768px) {
                p {
                  text-align: justify;
                }
              }
            `}
          >
            <Text className="comment">{text}</Text>
          </div>
          <Div
            mt="md"
            mb="md"
            ref={detailsContainerRef}
            className="comment"
            css={css`
              display: none;
              p {
                text-align: left;
                white-space: pre-line;
                font-size: 16px;
                font-weight: bold;
              }
            `}
          >
            <p
              className="comment"
              css={css`
                font-weight: bold !important;
              `}
              dangerouslySetInnerHTML={{
                __html: markup,
              }}
            />
          </Div>
        </Div>
        <Div
          css={css`
            flex-basis: 0;
            flex-grow: 1;

            order: 1;

            span {
              font-weight: bold;
            }

            @media (min-width: 768px) {
              padding: 0px 16px;
              order: 2;
            }
          `}
        >
          <ProjectMap {...location} />
        </Div>
      </Div>
    </Container>
  );
};

export default ProjectDescription;
