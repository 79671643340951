import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/react";
import Image from "gatsby-image";

const thumbnailCss = `display: block;
        transition: 0.15s all;
        margin-bottom: 16px;
        padding-top: 56.25%;
        position: relative;

        @media (min-width: 768px) {
           margin-bottom: 32px;
        }
        `;

export const ThumbnailImage = ({ featuredImage }) => (
  <Image
    fluid={featuredImage && featuredImage.fluid}
    css={css`
      position: absolute !important;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    `}
  />
);

export const ThumbnailLink = ({ featuredImage, url }) => {
  return (
    <Link
      to={url}
      css={css`
        ${thumbnailCss}
        background: #e9e9e9;
        width: 100%;

        &:hover {
          opacity: 0.75;
          cursor: pointer;
        }
      `}
    >
      <ThumbnailImage featuredImage={featuredImage} />
    </Link>
  );
};

export const Thumbnail = ({ featuredImage }) => {
  return (
    <div
      css={css`
        ${thumbnailCss}
      `}
    >
      <ThumbnailImage featuredImage={featuredImage} />
    </div>
  );
};
